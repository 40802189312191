.lesson-container-styles {
  height:  160px;
  min-height:  160px;
  display: flex;
  flex-direction: row;
  position: relative;
}
/* @media (max-width: 1200px) or (max-height: 900px) {
  .lesson-container-styles {
    height:  100px;
    min-height:  100px;
  }
} */

.main-app-container-styles {
  min-height: 600px;
  height: 100%;
}
@media (max-width: 1200px) or (max-height: 900px) {
  .main-app-container-styles {
    min-height: unset;
  }
}

.accuracy-container-styles {
  background-color: black;
  width: 300px;
}
/* @media (max-width: 1200px) or (max-height: 900px) {
  .accuracy-container-styles {
    width: 180px;
  }
} */

.accuracy-container-styles2 {
  font-size: 24pt;
  position: absolute;
  left: 60px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-top: 40px;
  /* z-index: 0; */
}
/* @media (max-width: 1200px) or (max-height: 900px) {
  .accuracy-container-styles2 {
    padding-top: 20px;
    left: 48px;
  }
} */


.tempo-container-styles {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari and Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
/* @media (max-width: 1200px) or (max-height: 900px) {
  .tempo-container-styles {
    margin-top: 8px;
  }
} */

/* @media (max-width: 1200px) or (max-height: 900px) {
  .tempo-row-styles {
    display: flex;
    align-items: center;
  }
} */


.tempo-dec-container-styles {
  margin-left: 0px;
  background: #414753;
  border: 2px solid #ADB1B7;
  border-radius: 4px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tempo-dec-container-styles:hover {
  background: rgba(65, 72, 83, .7);
  cursor: pointer;
  transition: 0.3s;
}

.tempo-inc-container-styles {
  margin-right: 1px;
  background: #414753;
  border: 2px solid #ADB1B7;
  border-radius: 4px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tempo-inc-container-styles:hover {
  background: rgba(65, 72, 83, .7);
  cursor: pointer;
  transition: 0.3s;
}

.header-text-styles {
  color: #CA7C62;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.01em;
}

.accuracy-title-text-styles {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ECECEC;
  padding-bottom: 10px;
}
/* @media (max-width: 1200px) or (max-height: 900px) {
  .accuracy-title-text-styles {
    font-size: 15px;
    padding-bottom: 4px;
  }
} */


.accuracy-text-styles {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  letter-spacing: 0.01em;
  color: #00C2FF;
}
/* @media (max-width: 1200px) or (max-height: 900px) {
  .accuracy-text-styles {
    font-size: 36px;
  }
} */
