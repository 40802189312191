

.paywall-desc-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  margin: 0;
}

.paywall-desc-title {
  color: var(--neutrals-100, #CED0D4);
  text-align: center;

  /* H3 */
  font-family: Lato;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.34px;

  margin: 0;
}

.paywall-desc-text {
  color: #CED0D4;
  text-align: center;
  
  /* Body 1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;

  max-width: 544px;
  margin: 0;
}

.modal-outer {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  flex-shrink: 0;
  background-color: #414753;
  opacity: 0.8;
  z-index: 12;
}

.modal-inner2 {
  position: fixed;
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  border-radius: 8px;
  background: linear-gradient(230deg, #1D2F44 -6.17%, #071423 89.04%);
  flex-shrink: 0;
  border-radius: 12px;
  justify-content: center;
  z-index: 13;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 40px 60px;
}
@media (max-height: 768px) {
  .modal-inner2 {
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 30px;
  }
}

.plan-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.plan-card {
  display: flex;
  /* width: 224px; */
  /* padding: 40px 24px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  /* background-color: red; */
}


/* Styles for Payment */

.payment-title {
  color: #CED0D4;
  text-align: center;
  font-family: Lato;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.34px;
}

.payment-form-container {
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text-input .MuiInputBase-input {
  position: relative;
  width: 245px;
  padding: 14px 20px;
  color: #ADB1B7;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}
.text-input .MuiOutlinedInput-root {
  border-radius: 80px;
}
/* Style the border when NOT focused */
.text-input .MuiOutlinedInput-root:not(.Mui-focused) {
  border: 1px solid #5B646E;
}
.text-input .MuiInputLabel-root {
  color: #ADB1B7;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}


.text-input iframe {
  width: 245px;
  height: 40px; /* you can adjust this based on your needs */
  border: none !important; /* important to override any default borders from Stripe */
}

/* Styling the Stripe elements to match your MUI styles */
.text-input .__PrivateStripeElement {
  position: relative;
  padding: 14px 20px;
  color: #ADB1B7;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}

/* Add border to Stripe Element */
.text-input .__PrivateStripeElement {
  border: 1px solid #5B646E;
  border-radius: 80px;
  box-shadow: none;
}

.text-input .MuiOutlinedInput-root {
  border-radius: 80px;
}

.text-input .MuiInputLabel-root {
  color: #ADB1B7;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}

.card-container {
  padding: 14px 20px;
  border: 1px solid #5B646E;
  border-radius: 80px;
  /* width: 100%; */
  /* background-color: red; */
}

.exp-cvv-container {
  width: 50%
}


.name-input .MuiOutlinedInput-root {
  border-radius: 80px;
  border: 1px solid #5B646E;
}

.name-input .MuiInputBase-input {
  position: relative;
  width: 245px;
  padding: 14px 20px;
  color: #ADB1B7; /* Color of the typed text */
  font-family: sans-serif; /* Should be Lato, but make Lato when fix stripe font! */
  font-size: 16px;
  font-weight: 500; /* should be 400 */
  line-height: normal;
  letter-spacing: 0.15px;
  &::placeholder {
    color: #ADB1B7; /* Color of the placeholder text */
    opacity: 1.0;
    font-weight: 500;
    letter-spacing: 0.15px;
  }
}

/* Does not work to eliminate the focus highlight... */
.name-input .MuiOutlinedInput-root.Mui-focused {
  border: 1px solid #5B646E;
  outline: none;
  box-shadow: none; /* Removes the default Material-UI box shadow on focus */
  border-color: #5B646E;
}
.name-input .MuiInputLabel-root {
  color: #ADB1B7;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}








/* Styles for Confirmation */
.confirmation-container {
  height: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation-title {
  color: #CED0D4;
  font-family: Lato;
  font-size: 48px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: 0.48px; 


  margin: 10px 0px;
}

.confirmation-desc {
  color: #CED0D4;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;

  margin-bottom: 100px;
}

.logout-container {
  position: fixed;
  bottom: 70px;
  left: 70px;
}
@media (max-height: 768px) {
  .logout-container {
    bottom: 50px;
    left: 50px;
  }
}

